<template>
  <v-container fluid class="pt-0">
    <v-row justify="center">
      <v-col cols="12">
        <MainModal
            :main="{ component: 'SmsSendConfig', title: 'Налаштування смс сервісу' }"
            :button="{ buttonHidden: true }"
            :item="Object.assign({}, current_item)"
            :modal="show_item_dialog"
            @updateItemModal="updateItemModal"
        />
        <v-card tile elevation="1" class="fill-height" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список налаштувань смс сервісів
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="grey lighten-4"
                      @click="openCreateDialog"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Створити нове налаштування смс сервісу</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="openComponentModal"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon>
              <v-icon size="26" color="success">
                mdi-format-list-numbered
              </v-icon>
            </template>
            <template v-slot:item.pass>
              ***************
            </template>
            <template v-slot:item.service_type="{ item }">
              {{ getSmsServiceSenderType(item.service_type) }}
            </template>
            <template v-slot:item.auth_type="{ item }">
              {{ getSmsAuthorizationType(item.auth_type) }}
            </template>
            <template v-slot:item.message_type="{ item }">
              {{ getSmsSendMessageTypeType(item.message_type) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_SMS_SEND_CONFIG} from "@/store/actions/sms_send_config";
import {getSmsAuthorizationType, getSmsServiceSenderType, getSmsSendMessageTypeType} from '@/utils/icons'

export default {
  props: ['selectable', 'model'],
  name: "SmsSendConfigView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getSmsSendConfigs'
    })
  },
  data() {
    return {
      selected: [],
      current_item: {},
      show_item_dialog: false,
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Найменування', value: 'name' },
        { text: 'Сервіс', value: 'service_type' },
        { text: 'Тип повідомлення', value: 'message_type' },
        { text: 'Авторизація', value: 'auth_type' },
        { text: 'Альфа-ім’я', value: 'alpha_name' },
        { text: 'Логін', value: 'login' },
        { text: 'Пароль', value: 'pass' },
      ],
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_ALL_SMS_SEND_CONFIG
    }),
    getSmsAuthorizationType,
    getSmsServiceSenderType,
    getSmsSendMessageTypeType,
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    openComponentModal(payload) {
      this.current_item = JSON.parse(JSON.stringify(payload))
      this.show_item_dialog = true
    },
    updateItemModal() {
      this.show_item_dialog = false
      this.current_item = {}
    },
    openCreateDialog() {
      this.current_item = {}
      this.show_item_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>