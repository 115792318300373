import { render, staticRenderFns } from "./SmsSendConfig.vue?vue&type=template&id=96af2daa&scoped=true&"
import script from "./SmsSendConfig.vue?vue&type=script&lang=js&"
export * from "./SmsSendConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96af2daa",
  null
  
)

export default component.exports